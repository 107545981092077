import consumer from "./consumer";

consumer.subscriptions.create(
  {
    channel: "ScheduleEvaluatorChannel",
  },
  {
    connected() {
      console.log("EvaluatorChannel connected");
    },

    disconnected() {
      console.log("EvaluatorChannel: Connection interrupted!");
    },

    received(data) {
      // eslint-disable-next-line no-undef
      if (data.id == business_id_for_action_cabel_channel) {
        if (data.state == "evaluation_faild") {
          alert(
            "Beim Validieren des Einsatzplans ist etwas schief gelaufen. Bitte kontaktiere uns unter support@pia-planer.ch oder versuche es erneut.\n\nDie Seite wird nun automatisch neu geladen.",
          );
          location.reload();
        } else {
          location.reload();
        }
      }
    },
  },
);
