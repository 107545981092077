import pia from "./pia-helper";

document.addEventListener("turbo:load", () => {
  pia.addListener(".side-nav-hamburger", "click", () => {
    pia.show(".pia-menu");
    pia.addClass(".close-side-nav", "xl:block");
    pia.addClass(".close-side-nav", "block");
    pia.removeClass(".close-side-nav", "hidden");
    pia.removeClass(".close-side-nav", "xl:hidden");

    pia.addClass(".open-side-nav", "hidden");
    pia.addClass(".open-side-nav", "xl:hidden");
    pia.removeClass(".open-side-nav", "block");
    pia.removeClass(".open-side-nav", "xl:block");
  });

  pia.addListener(".close-side-nav-icon", "click", () => {
    pia.hide(".pia-menu");
    pia.addClass(".close-side-nav", "hidden");
    pia.addClass(".close-side-nav", "xl:hidden");
    pia.removeClass(".close-side-nav", "block");
    pia.removeClass(".close-side-nav", "xl:block");

    pia.addClass(".open-side-nav", "block");
    pia.addClass(".open-side-nav", "xl:block");
    pia.removeClass(".open-side-nav", "hidden");
    pia.removeClass(".open-side-nav", "xl:hidden");
  });

  pia.addListener(".mobile-side-nav-hamburger", "click", () => {
    if (pia.isHidden(".pia-menu")) {
      pia.show(".pia-menu");
    } else {
      pia.hide(".pia-menu");
    }
  });
});
