import pia from "./pia-helper";

document.addEventListener("turbo:load", () => {
  if (!pia.selectFirst(".working_hours_reports-show")) {
    return;
  }

  pia.addListener(".single-change", "click", (e) => {
    const id = e.target.dataset["toggleId"];

    if (pia.isHidden(`#${id}`)) {
      pia.show(`#${id}`);
      e.target.innerText = e.target.dataset["hide"];
    } else {
      pia.hide(`#${id}`);
      e.target.innerText = e.target.dataset["show"];
    }
  });
});
