import pia from "./pia-helper";

document.addEventListener("turbo:load", () => {
  if (!pia.selectFirst(".user_privileges-index")) {
    return;
  }
  pia.addListener(".privilege-tooltip-anchor", "click", (event) => {
    if (event.target.parentElement.dataset.layout === "narrowLayout") {
      replaceTooltipForNarrowLayout(event);
    } else {
      replaceTooltipForWideLayout(event);
    }
  });

  pia.addListener(".privilege-tooltip-anchor", "mouseout", (event) => {
    const tooltip = event.target.parentElement.previousElementSibling;
    tooltip.style.marginLeft = "0px";
    tooltip.style.marginTop = "-32px";
    pia.hideElement(event.target.parentElement.previousElementSibling);
  });
});

const TOOLTIP_WIDTH = 288;
const SECURITY_SPACE = 50;

const replaceTooltipForNarrowLayout = (event) => {
  const tooltip = event.target.parentElement.previousElementSibling;
  pia.showElement(tooltip);
  const bottomBorderOfTooltip = tooltip.getBoundingClientRect().bottom;
  if (bottomBorderOfTooltip > window.innerHeight) {
    tooltip.style.marginTop = `-${-35 + tooltip.getBoundingClientRect().height}px`;
  } else {
    tooltip.style.marginTop = "-15px";
  }

  const businessNameCell =
    event.target.parentElement.parentElement.parentElement;
  const leftBorderOfBusinessNameCell =
    businessNameCell.getBoundingClientRect().left;
  if (leftBorderOfBusinessNameCell > TOOLTIP_WIDTH + SECURITY_SPACE) {
    tooltip.style.marginLeft = `-${193 + TOOLTIP_WIDTH}px`;
  } else {
    tooltip.style.marginLeft = "33px";
  }
};

const replaceTooltipForWideLayout = (event) => {
  const tooltip = event.target.parentElement.previousElementSibling;
  pia.showElement(tooltip);

  const tooltipWidth = 288;
  const securitySpace = 50;
  const rightBorderOfLinkTarget = event.target.getBoundingClientRect().right;
  if (
    rightBorderOfLinkTarget + tooltipWidth + securitySpace >
    window.innerWidth
  ) {
    tooltip.style.marginLeft = `-${8 + tooltipWidth}px`;
  } else {
    tooltip.style.marginLeft = "216px";
  }
};
