import pia from "../ex_assets/pia-helper";
import consumer from "./consumer";

consumer.subscriptions.create(
  {
    channel: "ScheduleTransformerChannel",
  },
  {
    connected: function () {
      console.log("TransformerChannel connected");
    },

    disconnected() {
      console.log("TransformerChannel: Connection interrupted!");
    },
    received: function (data) {
      // eslint-disable-next-line no-undef
      if (data.id == business_id_for_action_cabel_channel) {
        if (data.state == "transformation_success") {
          pia.createNotice(data.message);
          location.reload();
        } else {
          alert(data.message);
          location.reload();
        }
      }
    },
  },
);
