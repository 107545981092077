import "@hotwired/turbo-rails";

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import "../channels/schedule_evaluator_channel";
import "../channels/schedule_generator_channel";
import "../channels/schedule_transformer_channel";

import I18n from "../components/shared/pia-i18n";

import "@oddcamp/cocoon-vanilla-js";
import "chartkick/chart.js";

import "../ex_assets/side-nav";
import "../ex_assets/shifttypes";
import "../ex_assets/positions";
import "../ex_assets/position_configurations";
import "../ex_assets/business_optimization_rule";
import "../ex_assets/pia_field_help";
import "../ex_assets/main";
import "../ex_assets/business_configurations";
import "../ex_assets/allowance_rules";
import "../ex_assets/business-creation-wizard";
import "../ex_assets/privileges";
import "../ex_assets/working_hours_reports";

import "../controllers";

// eslint-disable-next-line no-undef
I18n.defaultLocale = piaI18n.defaultLocale;
// eslint-disable-next-line no-undef
I18n.locale = piaI18n.currentLocale;
