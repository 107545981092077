import pia from "./pia-helper";

document.addEventListener("turbo:load", () => {
  if (
    !pia.selectFirst(
      ".configurations-edit, .configurations-update, .configurations-new, .businesses-new, .businesses-create",
    )
  ) {
    return;
  }
  pia.addListener(
    "#business_configuration_working_hours_report_confirmation_by_position, #business_business_configuration_working_hours_report_confirmation_by_position",
    "change",
    () => {
      handleConfigurationBox();
    },
  );

  handleConfigurationBox();
});

const handleConfigurationBox = () => {
  if (
    pia.selectFirst(
      "#business_configuration_working_hours_report_confirmation_by_position, #business_business_configuration_working_hours_report_confirmation_by_position",
    )
  ) {
    if (
      pia.selectFirst(
        "#business_configuration_working_hours_report_confirmation_by_position, #business_business_configuration_working_hours_report_confirmation_by_position",
      ).checked
    ) {
      pia.removeClass("#working_hours_report_confirmations_box", "hide");
    } else {
      pia.addClass("#working_hours_report_confirmations_box", "hide");
      pia.selectFirst(
        "#business_configuration_working_hours_report_confirmations, #business_business_configuration_working_hours_report_confirmations",
      ).value = 0;
    }
  }
};
