export const pia = {
  selectAll: (selector) => {
    return document.querySelectorAll(selector);
  },

  selectFirst: (selector) => {
    return document.querySelector(selector);
  },

  hide: (selector) => {
    const elements = document.querySelectorAll(selector);
    if (elements) {
      elements.forEach((element) => pia.hideElement(element));
    }
  },

  hideElement: (element) => {
    element.style.setProperty("display", "none");
  },

  show: (selector, type) => {
    const elements = document.querySelectorAll(selector);
    if (elements) {
      elements.forEach((element) => pia.showElement(element, type));
    }
  },

  showElement: (element, type) => {
    element.style.setProperty("display", type || "block");
  },

  addClass: (selector, klass) => {
    const elements = document.querySelectorAll(selector);
    if (elements) {
      elements.forEach((element) => element.classList.add(klass));
    }
  },

  removeClass: (selector, klass) => {
    const elements = document.querySelectorAll(selector);
    if (elements) {
      elements.forEach((element) => element.classList.remove(klass));
    }
  },

  addListener: (selector, eventType, funktion) => {
    const elements = document.querySelectorAll(selector);
    if (elements) {
      elements.forEach((element) =>
        element.addEventListener(eventType, funktion),
      );
    }
  },

  isHidden: (selector) => {
    const elem = document.querySelector(selector);
    return pia.isHiddenElement(elem);
  },

  isHiddenElement: (element) => {
    return window.getComputedStyle(element).display === "none";
  },

  createNotice: (text) => {
    if (pia.selectAll(".info-notice-box")) {
      pia.selectAll(".info-notice-box").forEach((element) => {
        element.innerHTML = text;
      });
    } else if (pia.selectAll(".info-container")) {
      pia.selectAll(".info-container").forEach((element) => {
        element.innerHTML = `<div class='info-notice-box'>${text}</div>`;
      });
    }
  },

  toggleHidden: (element) => {
    element.classList.toggle("hidden");
  },
};

export default pia;
