import tippy from "tippy.js";
import pia from "./pia-helper";

document.addEventListener("turbo:load", () => {
  const helpIcons = pia.selectAll(".qs_field_help_anchor");

  if (!helpIcons) {
    return;
  }

  helpIcons.forEach((helpIcon) => {
    const tooltipId = helpIcon.getAttribute("data-tooltip-id");
    const template = document.getElementById(tooltipId);
    tippy(helpIcon.childNodes[0], {
      content: template.innerHTML,
      allowHTML: true,
      trigger: "click",
      theme: "light",
      placement: "right",
      maxWidth: 250,
    });
  });
});
