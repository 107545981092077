import pia from "../ex_assets/pia-helper";
import consumer from "./consumer";

consumer.subscriptions.create(
  {
    channel: "ScheduleGeneratorChannel",
  },
  {
    connected: function () {
      console.log("GeneratorChannel connected");
    },

    disconnected() {
      console.log("GeneratorChannel: Connection interrupted!");
    },
    received: function (data) {
      // eslint-disable-next-line no-undef
      if (data.id == business_id_for_action_cabel_channel) {
        if (data.state == "start_generation") {
          pia.createNotice(data.message);
        } else if (data.state == "generation_faild") {
          alert(data.message);
          location.reload();
        } else {
          const a = confirm(data.message);
          if (a) {
            location.href = data.redirect_url;
          }
        }
      }
    },
  },
);
