import tablesort from "tablesort";
import pia from "./pia-helper";

document.addEventListener("turbo:load", function () {
  pia.addListener(".color-box", "click", (e) => {
    const color = e.target.getAttribute("data-color");
    const colorInputField = pia.selectFirst("#shifttype_color");
    colorInputField.style.backgroundColor = color;
    colorInputField.value = color;
    pia.hide(".color-picker");
  });
  pia.addListener("#shifttype_color", "focus", () => {
    pia.show(".color-picker", "flex");
  });

  if (!pia.selectFirst("#shifttypes_table")) {
    return;
  }
  tablesort(pia.selectFirst("#shifttypes_table"));
});
