import pia from "./pia-helper";

document.addEventListener("turbo:load", () => {
  if (
    !pia.selectFirst(
      ".allowance_rules-edit, .allowance_rules-update, .allowance_rules-new, .allowance_rules-create",
    )
  ) {
    return;
  }

  pia.addListener("#ends_on_open", "change", () => {
    handleAvailableBox();
  });

  initAvailableBox();
});

const initAvailableBox = () => {
  if (pia.selectFirst("#ends_on_open").checked) {
    pia.addClass(".ends_on_box", "hide");
  } else {
    pia.removeClass(".ends_on_box", "hide");
  }
};

const handleAvailableBox = () => {
  if (pia.selectFirst("#ends_on_open").checked) {
    pia.selectFirst("#allowance_rule_ends_on").value = "2100-12-31";
    pia.addClass(".ends_on_box", "hide");
  } else {
    pia.selectFirst("#allowance_rule_ends_on").value = "";
    pia.removeClass(".ends_on_box", "hide");
  }
};
