import pia from "./pia-helper";

document.addEventListener("turbo:load", function () {
  pia.addListener(".open-icon-trigger", "click", (e) => {
    const positionId = e.target.dataset.positionId;
    pia.hide(`.position-config-holder-${positionId}`);
    pia.removeClass(`.border-bottom-trigger-${positionId}`, "no-border-bottom");
    pia.show(`.close-icon-trigger-${positionId}`);
    pia.hide(`.open-icon-trigger-${positionId}`);
  });

  pia.addListener(".close-icon-trigger", "click", (e) => {
    const positionId = e.target.dataset.positionId;
    pia.show(`.position-config-holder-${positionId}`, "table-row");
    pia.addClass(`.border-bottom-trigger-${positionId}`, "no-border-bottom");
    pia.hide(`.close-icon-trigger-${positionId}`);
    pia.show(`.open-icon-trigger-${positionId}`);
  });
});
