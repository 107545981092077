import pia from "./pia-helper";

document.addEventListener("turbo:load", () => {
  if (
    !pia.selectFirst(
      ".position_configurations-edit, .position_configurations-update, .position_configurations-new, .position_configurations-create",
    )
  ) {
    return;
  }

  pia.addListener("#position_configuration_employment_type", "change", (e) => {
    if (e.target.value != "fixed_workload") {
      pia.addClass(".fixed_workload_fields", "hide");
      pia.addClass(".target-hours", "hide");
    } else {
      pia.removeClass(".fixed_workload_fields", "hide");
      pia.removeClass(".target-hours", "hide");
    }
  });

  pia.addListener(
    "#position_configuration_hours_per_week, #position_configuration_percent",
    "change",
    () => {
      const hpw = Number(
        pia.selectFirst("#position_configuration_hours_per_week").value,
      );
      const percent = Number(
        pia.selectFirst("#position_configuration_percent").value,
      );
      pia.selectFirst("#effective_hours").value = (hpw * percent) / 100;
    },
  );

  pia.addListener("#enddate_open", "change", () => {
    handleAvailableBox();
  });

  pia.addListener(".distribute-to-seven", "click", () => {
    distributeTargetHours(7);
  });

  pia.addListener(".distribute-to-five", "click", () => {
    distributeTargetHours(5);
  });

  initAvailableBox();
});

const initAvailableBox = () => {
  if (pia.selectFirst("#enddate_open").checked) {
    pia.addClass(".enddate_box", "hide");
  } else {
    pia.removeClass(".enddate_box", "hide");
  }
};

const handleAvailableBox = () => {
  if (pia.selectFirst("#enddate_open").checked) {
    pia.selectFirst("#position_configuration_enddate").value = "2100-12-31";
    pia.addClass(".enddate_box", "hide");
  } else {
    pia.selectFirst("#position_configuration_enddate").value = "";
    pia.removeClass(".enddate_box", "hide");
  }
};

const distributeTargetHours = (number) => {
  const distributedValue = (
    pia.selectFirst("#effective_hours").value / number
  ).toFixed(4);
  let i = 0;
  for (i; i < number; i++) {
    pia.selectFirst(`.target-hour-input-${i}`).value = distributedValue;
  }
  if (number < 7) {
    let zeroIndex = number;
    for (zeroIndex; zeroIndex < 7; zeroIndex++) {
      pia.selectFirst(`.target-hour-input-${zeroIndex}`).value = 0;
    }
  }
};
