import { ClickOutsideController } from "stimulus-use";
import piaHelper from "@/ex_assets/pia-helper";

export default class extends ClickOutsideController {
  static targets = ["holdingMenu", "profileMenu"];

  toggleHoldingMenu() {
    piaHelper.toggleHidden(this.holdingMenuTarget);

    if (
      this.hasHoldingMenuTarget &&
      !piaHelper.isHiddenElement(this.holdingMenuTarget)
    ) {
      this.#hideProfileMenu();
    }
  }

  toggleProfileMenu() {
    piaHelper.toggleHidden(this.profileMenuTarget);

    if (!piaHelper.isHiddenElement(this.profileMenuTarget)) {
      this.#hideHoldingMenu();
    }
  }

  clickOutside() {
    if (
      this.hasHoldingMenuTarget &&
      !piaHelper.isHiddenElement(this.holdingMenuTarget)
    ) {
      this.#hideHoldingMenu();
    }

    if (
      this.hasProfileMenuTarget &&
      !piaHelper.isHiddenElement(this.profileMenuTarget)
    ) {
      this.#hideProfileMenu();
    }
  }

  #hideProfileMenu() {
    this.profileMenuTarget.classList.add("hidden");
  }

  #hideHoldingMenu() {
    if (this.hasHoldingMenuTarget) {
      this.holdingMenuTarget.classList.add("hidden");
    }
  }
}
